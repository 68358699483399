// export const BASE_URL = "http://192.168.85.58:7006";
// export const BASE_URL = "http://localhost:7001";
// export const BASE_URL = "http://hilife.ai";
// export const BASE_URL = "http://anubhuthi.org";
// export const BASE_URL = "http://hilifeai.in";
// export const BASE_URL = "http://hrms.ttsbs.com";
// export const BASE_URLNEW = "http://192.168.85.21:8001";
export const BASE_URL = "https://anubhuthiai.com";
// export const BASE_URL = "http://192.168.85.58:7006";

export const AUTH = {
  LOGIN: `${BASE_URL}/api/authlog`,
  GETUSER: `${BASE_URL}/api/auth`,
  GETAUTHROLE: `${BASE_URL}/api/authroles`,
  LOGOUT: `${BASE_URL}/api/authout`,
  PROJECTLIMIT: `${BASE_URL}/api/projectslimit`,
  TASKSLIMIT: `${BASE_URL}/api/allfiltertask`,
  ALLTASKS: `${BASE_URL}/api/alltasktime`,
  ALLUSERLIMIT: `${BASE_URL}/api/usersalllimit`,
  VERIFYTWOFA: `${BASE_URL}/api/verifytwofa`,
  VERIFYVIEWPASSWORD: `${BASE_URL}/api/verification/viewpassword`,
};
